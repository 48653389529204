import React from 'react'
import tw from 'twin.macro'
import { Spacer } from '@elements'
import { useStateContext } from '@context/stateContext'
import {
  JobsSection,
  TeamGalleryAfterWork,
  Subheader,
  TeamGallery,
} from '@modules'

const AboutPage = () => {
  const {
    content: {
      pages: {
        about: { sections },
        home: {
          sections: { jobs },
        },
      },
    },
  } = useStateContext()
  return (
    <Container>
      <Subheader data={sections.subheader} />
      <Spacer className="pb-10 md:pb-20 lg:pb-24 xl:pb-28" />
      <TeamGallery data={sections.teamGallery} />
      <Spacer className="pb-10 md:pb-20 lg:pb-24 xl:pb-28" />
      <TeamGalleryAfterWork data={sections.events} />
      <Spacer className="pb-10 md:pb-20 lg:pb-24 xl:pb-28" />
      <TeamGalleryAfterWork data={sections.teamGalleryAfterWork} spacer/>
      <JobsSection data={jobs} />
    </Container>
  )
}

export default AboutPage

const Container = tw.div`w-full bg-background overflow-x-hidden`
